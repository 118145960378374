import { AdminSeminarsUpdateRequest } from '@cleverbits-gmbh/iwimp-typescript-api'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import { useMessageActions } from 'lib/state'

import { adminService } from './AdminService'

const updateAdminSettings = (req: AdminSeminarsUpdateRequest) => {
  return adminService.updateSettings(req)
}

export const useAdminSeminarsUpdate = () => {
  const client = useQueryClient()
  const { addMessage } = useMessageActions()
  return useMutation(updateAdminSettings, {
    onSuccess: async () => {
      await client.invalidateQueries(['/admin/settings'])
      addMessage({ type: 'success', autoCloseSeconds: 5, text: 'Admin Settings erfolgreich aktualisiert.' })
    },
    onError: () => {
      addMessage({
        type: 'error',
        autoCloseSeconds: 5,
        text: 'Admin Settings konnten nicht aktualisiert werden.'
      })
    }
  })
}
