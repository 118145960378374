import { AdminInfoResponse } from '@cleverbits-gmbh/iwimp-typescript-api'
import { useQuery } from '@tanstack/react-query'

import { siteConfigService } from 'lib/api/SiteConfigService'

const fetcher = async (): Promise<AdminInfoResponse | null> => {
  try {
    return await siteConfigService.getAdminInfo()
  } catch (e) {
    return null
  }
}

export const useAdminInfo = () => {
  return useQuery(['/admin/infos'], fetcher)
}
