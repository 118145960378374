import { PropsWithChildren, useEffect } from 'react'

import { useProfile } from 'lib/api'
import { useAuthState } from 'lib/auth/useAuthState'

export const AuthProvider = ({ children }: PropsWithChildren) => {
  const { data } = useProfile()
  const { setUser } = useAuthState()

  useEffect(() => {
    setUser(data ?? null)
    if (data?.isFirstLogin) {
      callThorForCampaign()
    }
  }, [data])

  return <>{children}</>
}

function callThorForCampaign() {
  try {
    const method = new URL(window.location.href).searchParams.get('c') ?? 'direct_signup'
    // @ts-ignore thor is provided globally
    thor.pushEvent('sign_up', { method, av_category: 'account' })
  } catch (e) {
    console.error('Failed to provide thor with campaign.')
  }
}
