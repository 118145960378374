import Keycloak, { KeycloakLoginOptions } from 'keycloak-js'

const config = {
  url: process.env.REACT_APP_KEYCLOAK_URL,
  realm: process.env.REACT_APP_KEYCLOAK_REALM,
  clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID
}

const _kc = new Keycloak(config)

_kc.onAuthError = (error) => console.error(error)
_kc.onTokenExpired = () => _kc.updateToken(15)

const initKeycloak = (onAuthenticatedCallback: () => void) => {
  _kc
    .init({
      onLoad: 'check-sso',
      redirectUri: window.location.href,
      silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
      silentCheckSsoFallback: false
    })
    .then(() => {
      const originalCreateLoginUrl = _kc.createLoginUrl
      _kc.createLoginUrl = function (options: KeycloakLoginOptions) {
        const urlString = originalCreateLoginUrl.call(this, options)
        const url = new URL(urlString)
        url.searchParams.set('c', new URL(window.location.href).searchParams.get('c') ?? 'direct_signup')
        return url.toString()
      }
      return true
    })
    .catch(console.error)
    .finally(onAuthenticatedCallback)
}

const isAuthenticated = () => _kc.authenticated

const determineRedirectUri = (path?: string): string => {
  return path ? `${process.env.REACT_APP_PUBLIC_URL}${path}` : process.env.REACT_APP_PUBLIC_URL
}

const doLogin = (path?: string) => _kc.login({ redirectUri: determineRedirectUri(path) })

const doLogout = (path?: string) => {
  return _kc.logout({ redirectUri: determineRedirectUri(path) })
}

const getToken = () => _kc.token

const setToken = ({ accessToken, refreshToken }: { accessToken: string; refreshToken: string }) => {
  _kc.init({ token: accessToken, refreshToken: refreshToken, checkLoginIframe: false }).then((a) => console.log(a))
}

const isLoggedIn = () => _kc.authenticated

const updateToken = (successCallback: () => void) => _kc.updateToken(15).then(successCallback).catch(doLogin)

const getUsername = () => _kc.tokenParsed?.preferred_username

const hasRole = (roles: string[]) => roles.some((role) => _kc.hasRealmRole(role))

export const AuthService = {
  isAuthenticated,
  initKeycloak,
  doLogin,
  doLogout,
  isLoggedIn,
  getToken,
  updateToken,
  getUsername,
  setToken,
  hasRole
}
