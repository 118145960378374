import { AdminSeminarsResponse } from '@cleverbits-gmbh/iwimp-typescript-api'
import { useQuery } from '@tanstack/react-query'

import { PaginationState } from 'components/container'

import { adminService } from './AdminService'

const fetcher = async (state: PaginationState): Promise<AdminSeminarsResponse | null> => {
  try {
    return await adminService.getSettings(state)
  } catch (e) {
    return null
  }
}

export const useAdminSeminars = (state: PaginationState) => {
  return useQuery({
    queryKey: ['/admin/settings', state],
    queryFn: () => fetcher(state)
  })
}
