import {
  AdminApi,
  AdminInfoRequest,
  AdminInfoUpdateRequest,
  AdminMessageRequest,
  AdminMessageResponse,
  AdminSeminarsResponse,
  AdminSeminarsUpdateRequest,
  AdminUserListResponse,
  AdminUserResponse,
  FAQUpdateRequest,
  GetAdminSeminarsRequest,
  GetMarketingExportsRequest,
  GetUsersRequest,
  MarketingExportListResponse,
  UserEmailChangeAttemptResponse,
  UserRoleEnum
} from '@cleverbits-gmbh/iwimp-typescript-api'

import { ServiceBase } from 'lib/api/ServiceBase'

class AdminService extends ServiceBase {
  async getUsers(req: GetUsersRequest): Promise<AdminUserListResponse> {
    return new AdminApi(this.authenticated()).getUsers(req)
  }

  async getUser(id: number): Promise<AdminUserResponse> {
    return new AdminApi(this.authenticated()).getUser({ id })
  }

  async getSettings(params: GetAdminSeminarsRequest): Promise<AdminSeminarsResponse> {
    return new AdminApi(this.authenticated()).getAdminSeminars(params)
  }

  async getUserEmailChanges(id: number): Promise<UserEmailChangeAttemptResponse[]> {
    return new AdminApi(this.authenticated()).getUserEmailChanges({ id })
  }

  async getMarketingExports(req: GetMarketingExportsRequest): Promise<MarketingExportListResponse> {
    return new AdminApi(this.authenticated()).getMarketingExports(req)
  }

  async createAdminInfo(req: AdminInfoRequest): Promise<void> {
    return new AdminApi(this.authenticated()).createAdminInfo({ adminInfoRequest: req })
  }

  async updateAdminInfo(id: number, req: AdminInfoUpdateRequest): Promise<void> {
    return new AdminApi(this.authenticated()).updateAdminInfo({ id, adminInfoUpdateRequest: req })
  }

  async updateUserRoles(user: AdminUserResponse, roles: UserRoleEnum[]): Promise<void> {
    return new AdminApi(this.authenticated()).updateUserRoles({ id: user.id, userRolesUpdateRequest: { roles } })
  }

  async updateSettings(req: AdminSeminarsUpdateRequest): Promise<void> {
    return new AdminApi(this.authenticated()).updateAdminSeminars({ adminSeminarsUpdateRequest: req })
  }

  async updateUserPassword(user: AdminUserResponse, password: string): Promise<void> {
    return new AdminApi(this.authenticated()).updateUserPassword({
      id: user.id,
      userPasswordUpdateRequest: { password }
    })
  }

  async updateUserAllInclusive(user: AdminUserResponse, isAllInclusive: boolean): Promise<void> {
    return new AdminApi(this.authenticated()).updateAllInclusive({
      id: user.id,
      userAllInclusiveUpdateRequest: { isAllInclusive }
    })
  }

  async deleteUser(user: AdminUserResponse): Promise<void> {
    return new AdminApi(this.authenticated()).deleteUser({ id: user.id })
  }

  async updateFAQs(req: FAQUpdateRequest): Promise<void> {
    return new AdminApi(this.authenticated()).updateFAQs({ fAQUpdateRequest: req })
  }

  async triggerMarketingExport(): Promise<void> {
    return new AdminApi(this.authenticated()).triggerMarketingExport()
  }

  async getMessages(): Promise<AdminMessageResponse[]> {
    return new AdminApi(this.authenticated()).getAdminMessages()
  }

  async createAdminMessage(req: AdminMessageRequest): Promise<void> {
    return new AdminApi(this.authenticated()).createAdminMessage({ adminMessageRequest: req })
  }

  async updateAdminMessage(id: number, req: AdminMessageRequest): Promise<void> {
    return new AdminApi(this.authenticated()).updateAdminMessage({ id, adminMessageRequest: req })
  }
}

export const adminService = new AdminService()
